import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import ReadingTime from '../components/elements/reading-time'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = post.excerpt
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={`${post.frontmatter.title} | ${siteTitle}`}
        />

        <div
          className="postHero wave-mask"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            zIndex: 1
          }}
        >
          <p className="postDate">
            {post.frontmatter.date}
          </p>
          <h1
            style={{
              margin: '3rem 0'
            }}
          >
            {post.frontmatter.title}
          </h1>
          <ReadingTime text={post.html} />
        </div>
        <div className='gradient-top gradient-bottom'>
          <div
            className="postContent"
            style={{
              padding: '5rem 2rem 2rem',
              lineHeight: 1.5,
              position: 'relative',
              zIndex: 1
            }}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          <div
            className="postReadMore"
            style={{
              position: 'relative',
              zIndex: 1
            }}>
            <hr />
            <h2>Read more</h2>
            <ul
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                listStyle: 'none',
                padding: 0,
              }}
            >
              <li>
                {
                  previous &&
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                }
              </li>
              <li>
                {
                  next &&
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                }
              </li>
            </ul>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
