import React, { useEffect, useRef } from 'react'

const ReadingTime = ({ text }) => {
  const timer = useRef(null)
  useEffect(() => {
    const wpm = 225 // Word per minutes
    const words = text.trim().split(/\s+/).length
    const time = Math.ceil(words / wpm)
    let timerLabel = 'minute read'
    if (time > 1) {
      timerLabel = 'minutes read'
    }
    timer.current.innerText = `${time} ${timerLabel}`
  }, [])

  return (
    <span ref={timer}></span>
  )
}

export default ReadingTime
